import { useContext, useEffect, useState } from 'react';
import axios from 'axios';

export const ArticleCard = ({ merlinUrl }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (merlinUrl) {
      const fetchData = async () => {
        // Remove Auth header to prevent cross origin error
        delete axios.defaults.headers.common['Authorization'];

        await axios
          .get(merlinUrl)
          .then(async res => {
            let imageUrl = null;

            console.log(res.data);
            if (res.data.Identity.Relations?.socialmediaimagerel?.[0]) {
              imageUrl = `https://i2-prod.${new URL(
                res.data.Identity.Fields.seo_url,
              ).hostname.replace('www.', '')}/article${
                res.data.Identity.Relations?.socialmediaimagerel?.[0]?.id
              }.ece/ALTERNATES/s458/${
                res.data.Identity.Relations?.socialmediaimagerel?.[0]?.title
              }`;
            }

            console.log('image url', imageUrl);

            if (imageUrl) {
              console.log('getting image', imageUrl);
              await axios.get(imageUrl).catch(() => {
                imageUrl = null;
              });
            }

            // const pub = publications.filter(publication =>
            //   publication.handles.includes(
            //     res.data.Identity.Fields.primaryTag.split('@')[0].split(':')[1],
            //   ),
            // )[0].names[0];

            setData({ ...res.data, imageUrl });
          })
          .finally(() => setLoading(false));
      };

      fetchData()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [merlinUrl]);

  if (loading)
    return (
      <div className={`flex flex-col h-full rounded-tr rounder-br`} style={{}}>
        <div className="flex justify-center items-center mb-2 min-h-[160px] bg-slate-200 rounded">
          <svg
            className="w-12 h-12 text-slate-100"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 640 512"
          >
            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
          </svg>
        </div>

        <div className="h-2.5 bg-slate-100 rounded-full w-48 mb-2"></div>
        <div className="h-2.5 bg-slate-100 rounded-full w-40 mb-2"></div>
        <div className="h-2.5 bg-slate-100 rounded-full w-36"></div>
      </div>
    );

  if (!data) return null;

  return (
    <a
      href={data.Identity.Fields.seo_url}
      target="_blank"
      rel="noreferrer"
      className="no-underline text-gray-900 font-semibold"
    >
      <div
        className={`flex flex-col h-full rounded-tr rounder-br ${
          !data.imageUrl && ' min-h-[200px] px-2 border-l-2 bg-amber-50 border-amber-400'
        }`}
        style={{}}
      >
        <div className="relative">
          <img src={data.imageUrl} alt="" className="w-full rounded" />
        </div>

        <div className="text-base mt-1 ">{data.Identity.Title}</div>
        {/* <div className="text-sm text-slate-400">{data.publication}</div> */}
      </div>
    </a>
  );
};
