import { Grid, Typography } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ResultsRenderer from 'components/ResultsRenderer';
import useAuthState from 'auth/useAuthState';

const GET_CLASSES = ({ publicationUri, page = 1 }) => {
  return `
    PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    PREFIX core: <https://ontologies.kg.tm-awx.com/core#>
    
    SELECT ?o ?classLabel (COUNT(?tag) as ?tagCount)
    FROM <Tags>
    FROM <Concepts>
    FROM <ManualAnnotations>
    WHERE {
      ?tag <https://ontologies.kg.tm-awx.com/annotation#categorisedInto> ?o .
      ?o rdfs:label ?classLabel .
      {
        SELECT DISTINCT ?o WHERE {
          ?s <https://ontologies.kg.tm-awx.com/annotation#usedIn> <${publicationUri}> .
          ?s <https://ontologies.kg.tm-awx.com/annotation#categorisedInto> ?o .
   
        }
      }
    }
    GROUP BY ?o ?classLabel
    ORDER BY DESC (?tagCount)
    LIMIT 10
    OFFSET ${page * 10 - 10}
  `;
};

function App() {
  const { key } = useParams();
  const [authState] = useAuthState();
  const [classes, setClasses] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (authState.jwt) {
      axios
        .post(
          `${process.env.REACT_APP_CAT_API}/sparql`,
          GET_CLASSES({ page, publicationUri: decodeURIComponent(key) }),
          {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${authState.jwt}`,
            },
          },
        )
        .then(res => setClasses(res.data.results.bindings));
    }
  }, [page]);

  return (
    <div className="flex flex-col gap-4">
      <Grid item md={12}>
        <Link to="/publications">Back to Publications</Link>
      </Grid>
      <Grid item md={12}>
        <Typography variant="h5">Select a category:</Typography>
      </Grid>
      <div className="w-full">
        <table class="w-full text-sm text-left text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" class="px-6 py-3">
                Title
              </th>
              <th scope="col" class="px-6 py-3">
                Tags
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {classes.map(item => (
              <tr class="bg-white border-b  hover:bg-gray-50 ">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  {item.classLabel.value}
                </th>
                <td class="px-6 py-4">{item.tagCount.value}</td>
                <td class="px-6 py-4">
                  <a
                    href={`/publications/${key}/tags?hideFilter=true&category=${encodeURIComponent(
                      item.o.value,
                    )}`}
                    class="font-medium text-blue-600 hover:underline"
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full flex items-center justify-center mt-8">
        <button
          class={`inline-flex no-underline items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 rounded-lg border-none ${
            page === 1 ? 'bg-white opacity-20' : 'bg-white hover:bg-gray-100 hover:text-gray-700'
          }`}
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5 mr-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Previous
        </button>
        <button
          class="inline-flex no-underline items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border-none rounded-lg hover:bg-gray-100 hover:text-gray-700 "
          onClick={() => setPage(page + 1)}
        >
          Next
          <svg
            aria-hidden="true"
            class="w-5 h-5 ml-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
}

export default App;
