import { AppBar, Toolbar, Typography, Grid } from '@material-ui/core';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useAuthState from 'auth/useAuthState';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
  },
}));

const ReachAppBar = ({ title = process.env.REACT_APP_PRODUCT_NAME }) => {
  const classes = useStyles();
  const [authState] = useAuthState();

  return (
    <AppBar position="static" component={Link} to={'/'}>
      <Toolbar>
        <img
          src="https://jobs.reachplc.com/application/themes/trmrjobs1701/assets/img/brand_logo_reach.svg"
          alt="Reach Logo"
          style={{ height: '45px' }}
        />
        <Grid container>
          <Grid item md={10}>
            <Typography variant="h6" className="flex-grow pl-4">
              {title}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Grid container>
              <Grid item md={12}>
                {authState.authorised && (
                  <Avatar
                    name={authState.claims.name}
                    src={authState.claims.picture}
                    size="32"
                    round={true}
                  />
                )}
              </Grid>
              <Grid item md={12}>
                {authState.authorised && authState.claims.name}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <img src="cat.png" alt="Cat Logo" style={{ height: '64px' }} />
      </Toolbar>
    </AppBar>
  );
};

export default ReachAppBar;
