import SwaggerUI from 'swagger-ui-react';
import { useState } from 'react';
import useAuthState from 'auth/useAuthState';
import 'swagger-ui-react/swagger-ui.css';
import './swagger.css';

const ShowDoc = ({ mode, spec }) => {
  const [authState] = useAuthState();

  return (
    <SwaggerUI
      url={spec}
      onComplete={swaggerUi => swaggerUi.preauthorizeApiKey('bearerAuth', authState.jwt)}
    />
  );
};

export const ServiceContracts = () => {
  const [spec] = useState('https://newsletters.kg.tm-awx.com/__schema');

  return [<ShowDoc key="doc" mode="swagger" spec={spec} />];
};
