import { useState, useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import JWTDecode from 'jwt-decode';

import useAuthState from './useAuthState';

const GoogleSSOAuth = ({ authorised, unauthorised, checking, ...props }) => {
  const [authState, setAuthState] = useAuthState();

  const [currentlyChecking, setCurrentlyChecking] = useState(true);

  const googleResponse = res => {
    setCurrentlyChecking(false);
    setAuthState({
      authorised: true,
      jwt: res.getAuthResponse().id_token,
      claims: JWTDecode(res.getAuthResponse().id_token),
      loading: false,
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!authState.authorised) {
        setCurrentlyChecking(false);
        setAuthState({
          authorised: false,
          loading: false,
        });
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  });

  const Content = () => {
    if (authState.jwt) {
      return authorised({ profile: authState.claims, ...props });
    }

    if (currentlyChecking) {
      return checking(props);
    }

    return [
      ...unauthorised(props),
      <GoogleLogin
        key="GoogleLogin"
        clientId={process.env.REACT_APP_CLIENT_ID}
        onSuccess={googleResponse}
        prompt="consent"
      />,
    ];
  };

  return [
    <GoogleLogin
      key="GoogleLogin"
      clientId={process.env.REACT_APP_CLIENT_ID}
      isSignedIn={true}
      onSuccess={googleResponse}
      prompt="consent"
      render={() => <div />}
    />,
    <Content key="Content" />,
  ];
};

export default GoogleSSOAuth;
