import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MonitorOverviewIcon from '@material-ui/icons/Terrain';
import MonitorDetailIcon from '@material-ui/icons/ImageSearch';
import SearchIcon from '@material-ui/icons/Search';
import { ServiceContracts } from './pages/serviceContracts/ServiceContracts';
import Publications from './pages/publications/Publications';
import PublicationViewer from './pages/publications/PublicationViewer';
import Tags from './pages/tags/Tags';
import Articles from './pages/articles/Articles';

export const isReach = (_, profile) =>
  profile?.email.includes('@reachplc.com') || profile?.email.includes('@trinitymirror.com');

const apps = [
  {
    title: 'Service Contracts',
    permissionCheck: isReach,
    path: '/service-api-contracts',
    wildcard: true,
    icon: <AccountTreeIcon />,
    mainComponent: ServiceContracts,
  },
  {
    title: 'Service Monitoring Overview',
    path: 'https://d033aa5c.checklyhq.com/',
    icon: <MonitorOverviewIcon />,
  },
  {
    title: 'Service Monitoring Details',
    path: 'https://monitoring.kg.tm-awx.com/login/generic_oauth',
    icon: <MonitorDetailIcon />,
  },
  {
    title: 'Search Tags',
    permissionCheck: isReach,
    path: '/tags',
    icon: <SearchIcon />,
    mainComponent: Tags,
    pages: [
      {
        title: 'View Articles',
        path: '/tags/:tagUri/articles',
        mainComponent: Articles,
      },
    ],
  },
  {
    title: 'View Publications',
    permissionCheck: isReach,
    path: '/publications',
    icon: <SearchIcon />,
    mainComponent: Publications,
    pages: [
      {
        title: 'View Publication',
        path: '/publications/:key',
        mainComponent: PublicationViewer,
      },
      {
        title: 'Tags',
        path: '/publications/:key/tags',
        mainComponent: Tags,
      },
      {
        title: 'Tags',
        path: '/publications/:key/tags/:tagUri/articles',
        mainComponent: Articles,
      },
    ],
  },
];

export default apps;
