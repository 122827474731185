import { ReactiveBase, DataSearch, ReactiveList } from '@appbaseio/reactivesearch';
import { Grid } from '@material-ui/core';
import useAuthState from 'auth/useAuthState';
import * as ndjsonParser from 'ndjson-parse';
import * as arrayToNDJSON from 'array-to-ndjson';
import { useParams } from 'react-router-dom';
import ResultCard, { ArticleCard } from './ArticleCard';
import { useState, useEffect } from 'react';
import axios from 'axios';

const GET_ARTICLES = ({ page = 1, tagUri }) => {
  return `
    PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    PREFIX core: <https://ontologies.kg.tm-awx.com/core#>

    SELECT ?s ?articleUrl
    FROM <ManualAnnotations>
    WHERE {
      ?s <https://ontologies.kg.tm-awx.com/annotation#annotates> ?articleUrl .
      ?s <https://ontologies.kg.tm-awx.com/annotation#annotatesWith> <https://tags.kg.tm-awx.com/${tagUri.replaceAll(
        ' ',
        '%20',
      )}>
    }
    LIMIT 12
    OFFSET ${page * 12 - 12}
  `;
};

function Articles() {
  const [authState] = useAuthState();
  const { key, name, tagUri } = useParams();
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);

  console.log('Auth', authState);

  useEffect(() => {
    if (authState.jwt) {
      axios
        .post(`${process.env.REACT_APP_CAT_API}/sparql`, GET_ARTICLES({ page, tagUri }), {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${authState.jwt}`,
          },
        })
        .then(res => setArticles(res.data.results.bindings));
    }
  }, [page, tagUri]);

  return (
    <div>
      <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {articles.map(article => (
          <ArticleCard merlinUrl={article.articleUrl.value} />
        ))}
      </div>
      <div className="w-full flex items-center justify-center mt-8">
        <button
          class={`inline-flex no-underline items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 rounded-lg border-none ${
            page === 1 ? 'bg-white opacity-20' : 'bg-white hover:bg-gray-100 hover:text-gray-700'
          }`}
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5 mr-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Previous
        </button>
        <button
          class="inline-flex no-underline items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border-none rounded-lg hover:bg-gray-100 hover:text-gray-700 "
          onClick={() => setPage(page + 1)}
        >
          Next
          <svg
            aria-hidden="true"
            class="w-5 h-5 ml-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Articles;
