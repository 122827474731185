import { Route, Link } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

import apps from './apps';

const Layout = ({ children }) => (
  <>
    <Grid item md={1}>
      <Button component={Link} to="/">
        <HomeIcon />
      </Button>
    </Grid>
    <Grid item md={10}>
      <Grid item>{children}</Grid>
    </Grid>
    <Grid item md={1}></Grid>
  </>
);

const FullScreen = ({ children }) => (
  <>
    <div>Hello</div>
    <Grid item md={12}>
      {children}
    </Grid>
  </>
);

const getPages = ({ pages, permissionCheck, access }) =>
  (pages || []).map(page => ({ permissionCheck, access, ...page }));

const mainComponent = app => props => {
  if (app.fullPage) {
    return <FullScreen>{app.mainComponent(props)}</FullScreen>;
  }
  return <Layout>{app.mainComponent(props)}</Layout>;
};

const appRoutes = profile =>
  apps
    .map(app => [app, ...getPages(app)])
    .flat()
    .filter(app => !app.permissionCheck || app.permissionCheck(app, profile))
    .map(app => {
      const result = app.fullPage ? (
        <Route
          exact={!app.wildcard}
          path={app.path}
          key={app.path}
          component={mainComponent(app)}
        />
      ) : (
        <Route
          exact={!app.wildcard}
          path={app.path}
          key={app.path}
          component={mainComponent(app)}
        />
      );

      return result;
    });

export default appRoutes;
