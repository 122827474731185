import './App.css';
// import './index.css';
import './index.generated.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Typography, CssBaseline, Grid, CircularProgress } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import GoogleSSOAuth from 'auth/GoogleSSOAuth';

import { WelcomeBanner, WelcomePane } from './apps/Welcome';
import ReachAppBar from './layout/AppBar';
import ReachTheme from './layout/Theme';

import appRoutes from './apps/pageRoutes';

const Layout = ({ children }) => (
  <>
    <Grid item md={12}></Grid>
    <Grid item md={1}></Grid>
    <Grid item md={10}>
      <Grid item>{children}</Grid>
    </Grid>
    <Grid item md={1}></Grid>
  </>
);

function App() {
  return (
    <ThemeProvider theme={ReachTheme}>
      <CssBaseline />
      <Router>
        <GoogleSSOAuth
          authorised={({ profile }) => [
            <ReachAppBar />,
            <Grid container spacing={2} style={{ marginTop: 24, marginBottom: 100 }}>
              <Switch>
                <Route path="/" exact>
                  <Layout>
                    <WelcomeBanner />
                    <WelcomePane />
                  </Layout>
                </Route>
                {appRoutes(profile)}
              </Switch>
            </Grid>,
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                alignItems: 'center',
                background: 'lightgray',
                position: 'fixed',
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <p style={{ color: 'gray', padding: '0px 10px' }}>
                The Knowledge Graph Portal is still in development and is maintained by the Content
                Annotation Team. Information within may not be 100% fully up to date. If you have
                any questions, please contact #ask-content-annotation on the Reach Digital Slack.
              </p>
            </div>,
          ]}
          unauthorised={({ profile }) => [
            <ReachAppBar />,
            <Layout>
              <div>I'm sorry {profile && profile.given_name}, I can't let you do that.</div>
            </Layout>,
          ]}
          checking={() => [
            <ReachAppBar />,
            <Typography paragraph>Please wait, checking sign in state...</Typography>,
            <CircularProgress />,
          ]}
        />
      </Router>
    </ThemeProvider>
  );
}

export default App;
