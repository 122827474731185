import { createGlobalState } from 'react-hooks-global-state';
import axios from 'axios';

const initialState = { auth: { authorised: false, loading: true } };

const { useGlobalState } = createGlobalState(initialState);

const useAuthState = () => {
  const [authState, setAuthStateOriginal] = useGlobalState('auth');

  const setAuthState = state => {
    if (state.authorised) {
      axios.defaults.headers.common.Authorization = `Bearer ${state.jwt}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }

    setAuthStateOriginal(state);
  };

  return [authState, setAuthState];
};

export default useAuthState;
