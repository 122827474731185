import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import {
  ReactiveBase,
  DataSearch,
  ReactiveList,
  MultiList,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import useAuthState from 'auth/useAuthState';
import ResultsRenderer from 'components/ResultsRenderer';
import ExportButton from 'components/ExportButton';
import MenuItem from '@material-ui/core/MenuItem';
import * as ndjsonParser from 'ndjson-parse';
import * as arrayToNDJSON from 'array-to-ndjson';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import exportData from '../../../supporting/export';
import PublicationsList from '../../../publications.json';

const SEARCH_TAGS = ({
  term,
  publicationUri,
  classUri,
  page = 1,
  selectedClasses,
  selectedPublications,
}) => {
  const classUris = [];
  if (selectedClasses.length > 0) {
    for (const item of selectedClasses) {
      classUris.push(item.split(':')[3]);
    }
  }

  const publicationUris = [];
  if (selectedPublications.length > 0) {
    for (const item of selectedPublications) {
      publicationUris.push(item.split('/')[3]);
    }
  }

  return `
    PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    PREFIX core: <https://ontologies.kg.tm-awx.com/core#>

    SELECT ?uri ?label (COALESCE(COUNT(?article),0) as ?articleCount)
    FROM <Tags>
    FROM <ManualAnnotations>
    WHERE {
      {
        SELECT DISTINCT ?uri ?label WHERE {
          ?uri rdf:type <https://ontologies.kg.tm-awx.com/annotation#Tag> .
          ?uri rdfs:label ?label .
          ?uri <https://ontologies.kg.tm-awx.com/annotation#usedIn> ?publication .
          ?uri <https://ontologies.kg.tm-awx.com/annotation#categorisedInto> ?classUri .
          ${term ? `FILTER(REGEX(str(?label), "${term}", "i"))` : ''}
          ${
            publicationUris.length > 0
              ? `FILTER(REGEX(str(?publication),"${publicationUris.join('|')}", "i"))`
              : ''
          }
          ${
            classUris.length > 0
              ? `FILTER(REGEX(str(?classUri), "${classUris.join('|')}", "i"))`
              : ``
          }
        }
        GROUP BY ?uri ?label
        ORDER BY ASC(?label)
        LIMIT 10
        OFFSET ${page * 10 - 10}
      }
      OPTIONAL { ?article <https://ontologies.kg.tm-awx.com/annotation#annotatesWith> ?uri . }
    }
    GROUP BY ?uri ?label
    ORDER BY ASC(?label)
  `;
};

const GET_PUBLICATIONS = ({ selectedClasses }) => {
  const classUris = [];
  if (selectedClasses.length > 0) {
    for (const item of selectedClasses) {
      classUris.push(item.split(':')[3]);
    }
  }

  return `
    PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    PREFIX core: <https://ontologies.kg.tm-awx.com/core#>

    SELECT ?uri ?label (COUNT(?s) as ?tagCount)
    FROM <Tags>
    FROM <Concepts>
    FROM <ManualAnnotations>
    WHERE {
      {
        SELECT DISTINCT ?uri ?label
        WHERE {
          ?uri rdf:type core:Publication;
            rdfs:label ?label;
            core:altLabel ?altLabel .
        } 
      }
      ?s <https://ontologies.kg.tm-awx.com/annotation#usedIn> ?uri .
      ?s <https://ontologies.kg.tm-awx.com/annotation#categorisedInto> ?classUri .
      ${classUris.length > 0 ? `FILTER(REGEX(str(?classUri), "${classUris.join('|')}", "i"))` : ``}
    }
    GROUP BY ?uri ?label
    ORDER BY DESC (?tagCount)
  `;
};

const GET_CLASSES = ({ selectedPublications }) => {
  const publicationUris = [];
  if (selectedPublications.length > 0) {
    for (const item of selectedPublications) {
      publicationUris.push(item.split('/')[3]);
    }
  }

  console.log('publication uris', publicationUris);

  return `
    PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    PREFIX core: <https://ontologies.kg.tm-awx.com/core#>
    
    SELECT DISTINCT ?o ?classLabel (COUNT(?tag) as ?tagCount)
    FROM <Tags>
    FROM <Concepts>
    FROM <ManualAnnotations>
    WHERE {
      ?tag <https://ontologies.kg.tm-awx.com/annotation#categorisedInto> ?o .
      ?o rdfs:label ?classLabel .
      {
        SELECT DISTINCT ?o WHERE {
          ?s <https://ontologies.kg.tm-awx.com/annotation#usedIn> ?publication .
          ?s <https://ontologies.kg.tm-awx.com/annotation#categorisedInto> ?o .
          ${
            publicationUris.length > 0
              ? `FILTER(REGEX(str(?publication),"${publicationUris.join('|')}", "i"))`
              : ''
          }
        }
      }
    }
    GROUP BY ?classLabel
    ORDER BY DESC (?tagCount)  
  `;
};

function App() {
  // const [currentQuery, setCurrentQuery] = useState();
  const [authState] = useAuthState();
  const { key } = useParams();
  const [tags, setTags] = useState([]);
  const [publications, setPublications] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedPublications, setSelectedPublications] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { search } = useLocation();
  const hideFilter = new URLSearchParams(search).get('hideFilter');
  const category = new URLSearchParams(search).get('category');

  useEffect(() => {
    if (category) {
      setSelectedClasses([decodeURIComponent(category)]);
    }
  }, [category]);

  useEffect(() => {
    if (authState.jwt) {
      axios
        .post(
          `${process.env.REACT_APP_CAT_API}/sparql`,
          SEARCH_TAGS({
            page,
            term: searchTerm,
            selectedPublications: [decodeURIComponent(key), ...selectedPublications],
            selectedClasses: [decodeURIComponent(category), ...selectedClasses],
          }),
          {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${authState.jwt}`,
            },
          },
        )
        .then(res => setTags(res.data.results.bindings));
    }
  }, [page, searchTerm, selectedClasses, selectedPublications]);

  useEffect(() => {
    if (authState.jwt) {
      axios
        .post(`${process.env.REACT_APP_CAT_API}/sparql`, GET_PUBLICATIONS({ selectedClasses }), {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${authState.jwt}`,
          },
        })
        .then(res => setPublications(res.data.results.bindings));
    }
  }, [selectedPublications, selectedClasses]);

  useEffect(() => {
    if (authState.jwt) {
      axios
        .post(`${process.env.REACT_APP_CAT_API}/sparql`, GET_CLASSES({ selectedPublications }), {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${authState.jwt}`,
          },
        })
        .then(res => setClasses(res.data.results.bindings));
    }
  }, [selectedPublications, selectedClasses]);

  const selectClasses = event => {
    const { value } = event.target;
    if (event.target.checked) {
      setSelectedClasses([...selectedClasses, value]);
    } else {
      setSelectedClasses(selectedClasses.filter(val => val !== value));
    }
  };

  const selectPublications = event => {
    const { value } = event.target;
    if (event.target.checked) {
      setSelectedPublications([...selectedPublications, value]);
    } else {
      setSelectedPublications(selectedPublications.filter(val => val !== value));
    }
  };

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  // const exportToCSV = () => {
  //   exportData('csv', 'tags', currentQuery.query, currentQuery.sort);
  // };

  // const exportToJSON = () => {
  //   exportData('json', 'tags', currentQuery.query, currentQuery.sort);
  // };

  return (
    <div className="flex gap-4">
      <div className="flex flex-col w-1/4 gap-8">
        {!hideFilter && (
          <div>
            <span className="text-xs text-gray-700 uppercase bg-gray-50 font-semibold">
              Publications
            </span>
            <div className="h-[300px] mt-4 overflow-scroll">
              {publications.map((publication, index) => (
                <div class="flex items-center">
                  <input
                    id={`publications-${index}`}
                    type="checkbox"
                    value={publication.uri.value}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    onChange={selectPublications}
                  />
                  <label
                    for={`publications-${index}`}
                    class="ml-2 text-sm font-medium text-gray-900"
                  >
                    {publication.label.value}
                  </label>
                  <div className="flex-1 text-right">
                    <span className="text-gray-400">{publication.tagCount.value}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div>
          <span className="text-xs text-gray-700 uppercase bg-gray-50 font-semibold">
            Categories
          </span>
          <div className="h-[300px] mt-4 overflow-scroll">
            {classes.map((item, index) => (
              <div class="flex items-center">
                <input
                  checked={
                    selectedClasses.filter(
                      cls => decodeURIComponent(cls.split(':')[3]) === item.classLabel.value,
                    ).length > 0
                  }
                  id={`classes-${index}`}
                  type="checkbox"
                  value={item.o.value}
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  onChange={selectClasses}
                />
                <label for={`classes-${index}`} class="ml-2 text-sm font-medium text-gray-900">
                  {item.classLabel.value}
                </label>
                <div className="flex-1 text-right">
                  <span className="text-gray-400">{item.tagCount.value}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-3/4">
        <div class="relative overflow-x-auto">
          <div class="p-4 bg-white ">
            <label for="table-search" class="sr-only">
              Search {searchTerm}
            </label>
            <div class="relative mt-1">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  class="w-5 h-5 text-gray-500 "
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <input
                type="text"
                id="table-search"
                className="block p-2 pl-10 text-sm text-gray-900 border-none rounded-lg w-80 bg-gray-50"
                placeholder="Search..."
                // onBlur={}
                // value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
          <table class="w-full text-sm text-left text-gray-500 ">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Title
                </th>
                <th scope="col" class="px-6 py-3">
                  Articles
                </th>
                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {tags.map(tag => (
                <tr class="bg-white border-b  hover:bg-gray-50 ">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                    {tag.label.value}
                  </th>
                  <td class="px-6 py-4">{tag.articleCount.value}</td>
                  <td class="px-6 py-4">
                    <a
                      href={`/tags/${tag.uri.value.split('/')[3]}/articles`}
                      class="font-medium text-blue-600 hover:underline"
                    >
                      View
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="w-full flex items-center justify-center mt-8">
            <button
              class={`inline-flex no-underline items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 rounded-lg border-none ${
                page === 1
                  ? 'bg-white opacity-20'
                  : 'bg-white hover:bg-gray-100 hover:text-gray-700'
              }`}
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Previous
            </button>
            <button
              class="inline-flex no-underline items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border-none rounded-lg hover:bg-gray-100 hover:text-gray-700 "
              onClick={() => setPage(page + 1)}
            >
              Next
              <svg
                aria-hidden="true"
                class="w-5 h-5 ml-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
